@import "../../../sass//main.scss";

.format-16 {
  padding: 2.5rem 0 2rem;

  @include respond-below(lg) {
    padding: 2.5rem 1.5rem 2rem 1.5rem;
  }

  .content {
    max-width: 100%;

    .title {
      &.row {
        margin: 0;
      }

      h1 {
        width: 100%;
        color: $clr_txt_dark_green;
        font-family: "PlayfairDisplayRegular";
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 1.5rem;
      }
    }

    .desc {
      &.row {
        margin: 0;
      }

      p {
        width: 100%;
        color: $clr_txt_dark_black;
        font-family: "SansProRegular";
        font-size: 1rem;
        text-align: center;
        line-height: 1.9rem;
        margin-bottom: 1.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      img {
        width: 100%;
      }
    }

    .content-data {
      display: grid;
      grid-template-columns: 100%;
      grid-gap: 2rem;
      padding: 0;
      width: 100%;
      margin-top: 2rem;

      @include respond-above(md) {
        grid-template-columns: 45% 45%;
      }

       @include respond-above(lg) {
        grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
      }


      .grid-data {
        width: 100%;
        width: 100%;
        height: auto;
        position: relative;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
        -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
        border: none;

        @include respond-below(lg) {
          display: flex;
          flex-direction: column;
        }

        .badge {
          background: $clr_bg_white;
          position: absolute;
          top: 0;
          right: 6%;
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.4);
          -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.4);
          -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.4);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 10px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;

          .label-date {
            color: $clr_txt_dark_green;
            font-family: "SansProSemiBold";
            font-size: 1.5rem;
            font-weight: bold;
            text-align: justify;
            margin-bottom: 0.2rem;
          }

          .label-month {
            color: $clr_line_dark_gray;
            font-family: "SansProSemiBold";
            font-size: 1rem;
            font-weight: bold;
            text-align: justify;
            margin-bottom: 0.2rem;
          }

          .label-year {
            color: $clr_line_dark_gray;
            font-family: "SansProSemiBold";
            font-size: 1rem;
            font-weight: bold;
            text-align: justify;
          }
        }

        img {
          margin: 0;
          width: 100%;
          height: 22vw;
          object-position: top;
          object-fit: cover;

          @include respond-below(lg) {
            height: 100%;
          }
        }

        .grid-desc {
          background: $clr_bg_white;
          height: 230px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;
          padding: 25px;
          cursor: pointer;

          @include respond-below(lg) {
            text-align: center;
            justify-content: center;
            height: 100%;
            padding: 4;
            overflow: hidden;
            text-overflow: ellipsis;
            align-items: center;
          }

          &:hover {
            .box-icon-play {
              img {
                width: 20%;
                height: 20%;
              }
            }
          }
          .date {
            color: $clr_txt_dark_black;
            font-family: "SansProRegular";
            font-size: 1rem;
            margin-bottom: 0.1rem;
            font-weight: bold;
          }

          .title {
            color: $clr_txt_dark_black;
            font-family: "PlayfairDisplayRegular";
            font-size: 1.2rem;
            margin-bottom: 0.1rem;
            font-weight: bold;

            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;

            @include respond-below(lg) {
              font-size: 1rem;
            }
          }
          .cont {
            .short-desc {
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              overflow: hidden;
              
              @include respond-below(lg) {
                display: block;
                display: -webkit-box;
                max-width: 100%;
                height: 100%;
                margin: 1rem 0 1rem 0;
                font-size: 14px;
                line-height: 1.5;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;

              }
            }
          }
        }
      }
    }
  }

  .action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem 2rem;
  }
}
