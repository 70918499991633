@import "../../sass/main.scss";

.grievance-form {
  padding: 2.5rem 0 2rem;

  @include respond-below(lg) {
    padding: 2.5rem 2rem 2rem 2rem;
  }

  img {
    margin: 2rem 0;
  }

  .content {
      margin-bottom: 1.5rem;
    .title {
      &.row {
        margin: 0;
      }

      h1 {
        width: 100%;
        color: $clr_txt_dark_green;
        font-family: "PlayfairDisplayRegular";
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 1.5rem;
      }
    }

    .desc {
      &.row {
        margin: 0;
      }

      p {
        width: 100%;
        color: $clr_txt_dark_black;
        font-family: "SansProRegular";
        font-size: 1rem;
        text-align: center;
        line-height: 1.9rem;
        margin-bottom: 1.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      img {
        width: 100%;
      }
    }
  }

  .form-group-tap {
    label {
      color: $clr_txt_dark_green;
      font-family: "SansProSemiBold";
      font-size: 1.1rem;
      margin-bottom: 0;
      span.require {
        margin-left: 2px;
        color: $clr_error_sign;
      }
    }

    .files{
      margin: .5rem 0 .8rem;
    }

    input {
      font-family: "SansProRegular";
      font-size: 1rem;
      color: #000000;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $clr_line_gray;
      padding: 0;

      &:focus{
          box-shadow: none;
          border-bottom: 1px solid $clr_txt_dark_green;
      }

      &::placeholder{
          color: $clr_line_gray;
      }
    }

    small.error-text {
      color: $clr_txt_gray;
      margin-bottom: 1rem;

    }
  }
}

// <div class="form-group-tap col-lg-6 col-md-6 col-sm-12">
// <label class="form-label" for="inputFullName">
//   Email address<span class="rquire">*</span>
// </label>
// <input
//   placeholder="Full Name"
//   type="text"
//   id="inputFullName"
//   class="form-control"
// />
// <small class="error-text form-text">
//   We'll never share your email with anyone else.
// </small>
// </div>
