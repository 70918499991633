@import "../../../sass//main.scss";

.format-4 {
  padding: 0;
  display: inline-block;
  position: relative;

  @include respond-above(lg) {
    margin: 2.5rem 0 2rem;
    max-width: calc(100% / 2);
    height: 700px;
  }

  img {
    position: relative;
    z-index: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .content {
    z-index: 1;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 0 16% 3rem;
    position: absolute;
    top: 0;
    left: 0;
    @include respond-below(lg) {
      margin-bottom: 1rem;
      align-items: center;
      position: inherit;
      padding: 0rem 2rem 0 2rem;
      justify-content: flex-start;
    }
    .title {
      &.row {
        margin: 0;
      }

      h1 {
        width: 100%;
        color: $clr_txt_white;
        font-family: "PlayfairDisplayRegular";
        font-size: 2.5rem;
        text-align: left;
        margin-bottom: 1.5rem;
        margin-top: 3rem;
        @include respond-below(lg) {
          text-align: center;
          margin-top: 1rem;
          color: black;
        }
      }
    }

    .subtitle {
      &.row {
        margin: 0;
      }

      span {
        width: 100%;
        color: $clr_txt_white;
        font-family: "SansProLight";
        font-size: 1.5rem;
        font-style: italic;
        text-align: left;
        line-height: 1.9rem;
        margin-bottom: 1.5rem;
        @include respond-below(lg) {
          text-align: center;
          color: black;
        }
        &::before,
        &::after {
          content: '"';
        }
      }
    }

    .desc {
      &.row {
        margin: 0;
        margin-bottom: 1rem;
        @include respond-above(lg) {
          margin-bottom: 2.5rem;
        }
      }

      p {
        width: 100%;
        color: $clr_txt_white;
        font-family: "SansProLight";
        font-size: 1rem;
        text-align: left;
        line-height: 1.9rem;
        margin-bottom: 1rem;
        @include respond-below(lg) {
          text-align: center;

          color: black;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }

      * {
        width: 100%;
      }
    }
  }
}
