@import "../../../sass//main.scss";

.format-8 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 1.5rem;
  margin-bottom: 1rem;
  @include respond-above(lg) {
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2.5rem 0 2rem;
  }

  .box-img-photo {
    margin: 0;
    justify-content: center;
    align-items: center;

    @include respond-above(lg) {
      width: 35%;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0;
    }

    img {
      &.photo {
        //margin: 2rem 0;
      }
    }
  }

  .content {
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;

    flex-direction: column;
    margin: 0;
    @include respond-above(lg) {
      width: 65%;
      padding-left: 2.5rem;
    }

    .title {
      &.row {
        width: 100%;
        margin: 0;
        
        @include respond-below(lg) {
          margin-top: 1rem;
        }
      }

      h1 {
        width: 100%;
        color: $clr_txt_dark_green;
        font-family: "PlayfairDisplayRegular";
        margin-bottom: 1.5rem;
        font-size: 1.5rem;
        text-align: center;

        @include respond-above(lg) {
          font-size: 2.5rem;
          text-align: left;
        }
      }
    }

    .subtitle {
      &.row {
        width: 100%;
        margin: 0;
      }

      h5 {
        width: 100%;
        color: $clr_txt_dark_black;
        font-family: "PlayfairDisplayRegular";
        font-style: italic;
        font-size: 1.8rem;
        text-align: center;
        @include respond-above(lg) {
          text-align: left;
        }
      }
    }

    .desc {
      &.row {
        margin: 0;
        width: 100%;

      }

      ol, ul, dl {
        width: 100% !important;
        padding-left: 1rem;
        a {
          overflow-wrap: break-word;
        }
      }

      p {
        width: 100%;
        color: $clr_txt_dark_black;
        font-family: "SansProRegular";
        font-size: 1rem;
        text-align: center;
        line-height: 1.9rem;
        margin-bottom: 1.5rem;
        @include respond-above(lg) {
          text-align: justify;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }

      img {
        width: 100%;
      }
    }

    .divider {
      &.row {
        width: 100%;
        margin: 0;

        .col {
          padding: 0;

          hr {
            border-color: $clr_line_dark_gray;
          }
        }
      }
    }
  }
}
