@import "../../sass/main.scss";

.video-modal {
  .modal-content {
    width: 90%;
    margin: 0 auto;
    .modal-body {
      padding: 0.8rem;
      .player-wrapper {
        position: relative;
        height: 70vh;
        width: 100%;

        .react-player {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}

@include respond-above(lg) {
  .video-modal {
    .modal-content {
      width: 100%;
      .modal-body {
        padding: 1.5rem;
        .player-wrapper {
          position: relative;
          height: 70vh;
          width: 100%;

          .react-player {
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
}
