@import "../../../sass//main.scss";

.format-3 {
  padding: 0;
  display: inline-block;
  position: relative;
  height: 450px;
  @include respond-above(lg) {
    max-width: calc(100% / 2);
    height: 550px;
    margin: 2.5rem 0 2rem;
  }

  img {
    position: relative;
    z-index: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .content {
    width: 100%;
    padding: 0 4%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: column;
    @include respond-above(lg) {
      padding: 0 16%;
      justify-content: center;
    }

    .title {
      &.row {
        margin: 0;
      }

      h1 {
        width: 100%;
        color: $clr_txt_white;
        font-family: "PlayfairDisplayRegular";
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 1.5rem;
        margin-top: 3rem;
      }
    }

    .subtitle {
      &.row {
        margin: 0;
      }

      span {
        width: 100%;
        color: $clr_txt_white;
        font-family: "SansProLight";
        font-size: 1.5rem;
        font-style: italic;
        text-align: center;
        line-height: 1.9rem;
        margin-bottom: 1.5rem;

        &::before,
        &::after {
          content: '"';
        }
      }
    }

    .desc {
      &.row {
        margin: 0;
      }

      p {
        width: 100%;
        color: $clr_txt_white;
        font-family: "SansProLight";
        font-size: 1rem;
        text-align: center;
        line-height: 1.9rem;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      * {
        width: 100%;
      }
    }
  }
}
