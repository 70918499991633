@import "../../../sass//main.scss";

.format-7 {
  padding: 0;
  display: inline-block;
  position: relative;
  height: 450px;
  @include respond-above(lg) {
    max-width: calc(100% / 2);
    height: 550px;
  }

  img {
    position: relative;
    z-index: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .content {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 15% 4% 0;
    @include respond-below(lg) {
      justify-content: center;
      padding: 0 10%;
    }

    .img-icon {
      width: 20%;
      height: 20%;
      object-fit: contain;
      margin-bottom: 3%;

      @include respond-below(lg) {
        width: 30%;
      height: 30%;
      margin-bottom: 2%;
      }
    }

    ul {
      color: $clr_txt_white;
      font-family: "SansProLight";
      font-size: 1.2rem;
    }

    .title {
      &.row {
        display: contents;
        margin: 0;
      }

      h1 {
        width: 100%;
        color: $clr_txt_white;
        font-family: "PlayfairDisplayRegular";
        font-size: 2.5rem;
        text-align: center;
        @include respond-below(lg) {
          font-size: 2rem;
        }
      }
    }

    .subtitle {
      &.row {
        margin: 0;
      }

      span {
        width: 100%;
        color: $clr_txt_white;
        font-family: "SansProLight";
        font-size: 1.5rem;
        font-style: italic;
        text-align: center;
        line-height: 1.9rem;
        margin-bottom: 1.5rem;

        @include respond-below(lg) {
          font-size: 1rem;
        }

        &::before,
        &::after {
          content: '"';
        }
      }
    }

    .desc {
      &.row {
        margin: 0;
      }

      p {
        width: 100%;
        color: $clr_txt_white;
        font-family: "SansProLight";
        font-size: 1rem;
        text-align: center;
        line-height: 1.9rem;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      * {
        width: 100%;
      }
    }
  }
}
