@import "../../sass/main.scss";

.container-xl {
  &.footer-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem;

    .footer-logo {
      display: flex;
      justify-content: center;
      img {
        width: 70%;
        object-fit: contain;
      }
    }

    .footer-content{
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      div{
        text-align: center !important;
      }

      h5{
        color: $clr_line_dark_gray;
        font-family: "SansProSemiBold";
        text-align: center !important;
      }

      p{
        text-align: center;
        width: 100%;
        color: $clr_txt_gray;
        font-family: "SansProRegular";
        font-size: 1rem;
        text-align: center;
        line-height: 1.3rem;
        margin-bottom: 1rem;
        text-align: center !important
      }

      label{
        span{
          &.title{
            font-family: "SansProSemiBold";
            color: $clr_line_dark_gray;
            text-transform: capitalize;
            margin-right: 5px;
          }

          &.value{
            font-family: "SansProRegular";
            color: $clr_txt_gray;
          }
        }
      }
    }

    .menu-social-media{
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;

      img{
        margin:0 .5rem;
        width: 11%;
        object-fit: contain;
      }
    }

    .copyright{
      margin-top: 2rem;
      font-family: "SansProRegular";
            color: $clr_txt_gray;
            width: 100%;
            text-align: center;
    }
  }

  &.footer-desktop {
    display: none;
    background: $clr_bg_light_gray;
    /* margin-bottom: 4rem; */
    //margin-top: 4rem;
    max-width: 100%;
    padding: 3rem 5% 3rem;

    h5 {
      color: $clr_txt_gray;
      font-family: "SansProBold";
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 0.2rem;
      color: $clr_txt_gray;
    }

    .contact {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      margin-bottom: 1rem;

      label {
        margin-bottom: 0.2rem;
        span {
          color: $clr_txt_gray;
          &.title {
            font-family: "SansProBold";
            margin-right: 0.3rem;
          }
        }
      }
    }

    .footer-logo {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      img {
        width: 50%;
        object-fit: contain;
      }
    }

    .box-quick-links {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      color: $clr_txt_dark_black;

      .title {
        font-family: "PlayfairDisplayRegular";
      }

      .footer-menu {
        span {
          margin: 0 1.1rem;
          cursor: pointer;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .box-social-media {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      color: $clr_txt_gray;

      .menu-social-media {
        img {
          object-fit: contain;
          margin: 0 0.5rem;
          cursor: pointer;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    hr {
      border-color: $clr_line_dark_gray;
    }
  }
}

@include respond-above(lg) {
  .container-xl {
    &.footer-mobile {
      display: none;
    }

    &.footer-desktop {
      display: block;
    }
  }
}
