@import "../../../sass//main.scss";

.format-12 {
  padding: 2.5rem 0 2rem;
  @include respond-below(lg) {
    padding: 2.5rem 1.5rem 2rem 1.5rem;
  }
  .content {
    max-width: 100%;

    .title {
      &.row {
        margin: 0;
      }

      h1 {
        width: 100%;
        color: $clr_txt_dark_green;
        font-family: "PlayfairDisplayRegular";
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 1.5rem;
      }
    }

    .desc {
      &.row {
        margin: 0;
      }

      p {
        width: 100%;
        color: $clr_txt_dark_black;
        font-family: "SansProRegular";
        font-size: 1rem;
        text-align: center;
        line-height: 1.9rem;
        margin-bottom: 1.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      img {
        width: 100%;
      }
    }

    .tab-menu {
      a {
        color: $clr_txt_dark_black;
        font-family: "PlayfairDisplayRegular";
        font-size: 1.4rem;
        padding: 0 0 0.5rem;
        margin: 0 2rem;
        cursor: pointer;
        border: none;
        border-bottom: 4px solid transparent;

        &:hover {
          border-bottom: 4px solid #81c42a;
        }

        &.nav-item {
          &.nav-link {
            &.active {
              border: none;
              border-bottom: 4px solid #81c42a;
            }
          }
        }
      }
    }

    .content-data {
      display: grid;
      grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
      grid-gap: 2rem;
      padding: 0;
      width: 100%;
      margin-top: 2rem;
      @include respond-below(lg) {
        grid-template-columns: 45% 45%;
        margin-bottom: 2rem;
      }

      .grid-data {
        cursor: pointer;
        width: 100%;
        width: 100%;
        height: 40vw;
        position: relative;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
        -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
        border: none;
        @include respond-below(lg) {
          height: 100%;
        }

        .badge {
          background: #ffffff;
          position: absolute;
          top: 0;
          right: 6%;
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.4);
          -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.4);
          -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.4);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 10px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;

          .label-year {
            color: $clr_txt_dark_green;
            font-family: "SansProSemiBold";
            font-size: 1.5rem;
            font-weight: bold;
            text-align: justify;
          }

          .label-report {
            color: $clr_line_dark_gray;
            font-family: "SansProSemiBold";
            font-size: 1rem;
            font-weight: bold;
            text-align: justify;
          }
        }

        img {
          margin: 0;
          width: 100%;
          height: 85%;
          object-position: center;
          object-fit: cover;
        }

        .grid-desc {
          border-top: 1px solid #00000038;
          height: 15%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;

          @include respond-below(lg) {
            height: 100%;
            padding: 1rem;
          }

          .title {
            color: $clr_txt_dark_green;
            font-family: "PlayfairDisplayRegular";
            font-size: 1.2rem;
            margin-bottom: 0.1rem;
            font-weight: bold;
          }
        }
      }
    }
  }

  .filter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond-below(lg) {
      flex-direction: column;
    }

    .title {
      color: $clr_txt_dark_black;
      font-family: "SansProSemiBold";
      font-size: 1rem;
      font-weight: bold;
    }

    .action-select {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      @include respond-below(lg) {
        width: 100%;
        justify-content: space-between;
        margin-top: 1rem;
      }

      .dropdown {
        margin-left: 10px;

        .btn-primary {
          margin-left: 10px;
          background-color: transparent;
          border: none;
          color: black;
          border-bottom: 1px solid #00000038;
          border-radius: 0;
        }
      }
    }
  }

  .action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem 2rem;
  }
}
