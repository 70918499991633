@import "../../sass//main.scss";


.filter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond-below(lg) {
      justify-content: center;
    }

    .title {
      color: $clr_txt_dark_black;
      font-family: "SansProSemiBold";
      font-size: 1rem;
      font-weight: bold;
    }

    .action-select {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      .dropdown {
        margin-left: 10px;

        .btn-primary {
          margin-left: 10px;
          background-color: transparent;
          border: none;
          color: black;
          border-bottom: 1px solid #00000038;
          border-radius: 0;
        }
      }
    }
  }