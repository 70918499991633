@import "../../sass/main.scss";

.button-fill{
    font-family: "SansProRegular";
    font-size: .8rem;
    text-transform: uppercase;
    color: $clr_txt_dark_black;
    padding: 8px 18px;
    border-radius: 5px;
    border: none;
    background-color: $clr_bg_white;
}

.button-fill-green{
    font-family: "SansProRegular";
    font-size: .8rem;
    text-transform: uppercase;
    color: $clr_txt_white;
    background-color: $clr_bg_dark_green;
    padding: 8px 18px;
    border-radius: 5px;
    border: none;
    border: 1px solid $clr_bg_dark_green;

    &:hover{
        color: $clr_bg_dark_green;
        background-color: $clr_bg_white;
        border: 1px solid $clr_bg_dark_green;
    }
}

.button-outline-green{
    font-family: "SansProRegular";
    font-size: .8rem;
    text-transform: uppercase;
    color: $clr_bg_dark_green;
    border: 1px solid $clr_bg_dark_green;
    background-color: $clr_bg_white;
    padding: 8px 18px;
    border-radius: 5px;

    &:hover{
        color: $clr_bg_white;
        background-color: $clr_bg_dark_green;
    }
}