@import "../../sass/main.scss";

.box-banner-slideshow {
  @include respond-below(lg) {
    margin-top: 80px;
  }
  width: 100%;
  height: calc(100vh - 150px);
  background-color: $clr_line_gray;

  .slick-dots{
      bottom: 25px;

      .slick-active{
        button{
            &::before{
                font-size: 40px;
                opacity: 1;
                color: $clr_txt_light_green;
            }
        }
      }

      button{
          &::before{
              font-size: 40px;
              opacity: 1;
              color: $clr_txt_white;
          }
      }
  }

  .slider-slideshow {
    height: inherit;
    .slick-list {
      height: inherit;
      .slick-track {
        height: inherit;
        .slick-slide {
          height: inherit;
          div {
            height: inherit;
            .content-slide {
              position: relative;
              width: 100% !important;
              height: 100% !important;
              display: flex !important;
              justify-content: center;
              align-items: center;

              h1.banner-title {
                cursor: pointer;
   
                position: relative;
                z-index: 1;

                width: 100%;
                color: $clr_txt_white;
                font-family: "PlayfairDisplayRegular";
                text-align: center;
                font-size: 3rem;
                padding: 7rem;

                @include respond-below(lg) {
                  font-size: 2rem;
                  padding: 1.5rem;
                }
              }

              img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }
}
