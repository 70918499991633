@import "../../../sass//main.scss";

.format-9 {
  background-color: $clr_bg_gray;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 100%;
  padding: 2.5rem 0 2rem;

  .container-format-9 {
    padding: 0;
    display: flex;
    flex-direction: column;

    @include respond-above(lg) {
      flex-direction: row-reverse;
    }

    .box-img-photo {
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 1.5rem;
      @include respond-above(lg) {
        width: 35%;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0;
      }
      img {
        &.photo {
          //margin: 2rem 0;
        }
      }
    }

    .content {
      justify-content: center;
      align-items: center;
      display: flex;
      padding: 0 2rem;
      flex-direction: column;
      margin: 0;
      @include respond-above(lg) {
        width: 65%;
        padding: 0;
        justify-content: flex-start;
        align-items: flex-start;
        padding-right: 2.5rem;
      }

      .title {
        &.row {
          margin: 0;

          @include respond-below(lg) {
            margin-top: 1rem;
          }
        }

        h1 {
          width: 100%;
          color: $clr_txt_dark_green;
          font-family: "PlayfairDisplayRegular";
          font-size: 2.5rem;
          text-align: left;
          margin-bottom: 1.5rem;

          @include respond-below(lg) {
            font-size: 1.5rem;
            text-align: center;
          }
        }
      }

      .subtitle {
        &.row {
          margin: 0;
        }

        h5 {
          width: 100%;
          color: $clr_txt_dark_black;
          font-family: "PlayfairDisplayRegular";
          font-style: italic;
          font-size: 1.8rem;
          text-align: left;
        }
      }

      .desc {
        &.row {
          margin: 0;
        }

        ol, ul, dl {
          width: 100% !important;
          padding-left: 1rem;
          a {
            overflow-wrap: break-word;
          }
        }

        p {
          text-align: center;
          width: 100%;
          color: $clr_txt_dark_black;
          font-family: "SansProRegular";
          font-size: 1rem;
          line-height: 1.9rem;
          margin-bottom: 1.5rem;
          @include respond-above(lg) {
            text-align: justify;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        img {
          width: 100%;
        }
      }

      .divider {
        &.row {
          width: 100%;
          margin: 0;

          .col {
            padding: 0;

            hr {
              border-color: $clr_line_dark_gray;
            }
          }
        }
      }
    }
  }
}
