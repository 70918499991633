@import "../../../sass//main.scss";

.modal-profile {
  @include respond-below(lg) {
    margin-top: 80px;
    height: calc(100vh - 90px);
  }
}
.format-11 {
  padding: 2.5rem 0 2rem;

  @include respond-below(lg) {
    padding: 2.5rem 1rem 2rem 1rem;
  }

  .content {
    max-width: 100%;

    .title {
      &.row {
        margin: 0;
      }

      h1 {
        width: 100%;
        color: $clr_txt_dark_green;
        font-family: "PlayfairDisplayRegular";
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 1.5rem;
      }
    }

    .desc {
      &.row {
        margin: 0;
      }

      p {
        width: 100%;
        color: $clr_txt_dark_black;
        font-family: "SansProRegular";
        font-size: 1rem;
        text-align: center;
        line-height: 1.9rem;
        margin-bottom: 1.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      img {
        width: 100%;
      }
    }

    .tab-menu {
      a {
        color: $clr_txt_dark_black;
        font-family: "PlayfairDisplayRegular";
        font-size: 1.4rem;
        padding: 0 0 0.5rem;
        margin: 0 2rem;
        cursor: pointer;
        border: none;
        border-bottom: 4px solid transparent;

        &:hover {
          border-bottom: 4px solid #81c42a;
        }

        &.nav-item {
          &.nav-link {
            &.active {
              border: none;
              border-bottom: 4px solid #81c42a;
            }
          }
        }
      }
    }

    .content-data {
      display: grid;
      grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
      grid-gap: 15px;
      padding: 0;
      width: 100%;
      margin-top: 2rem;

      @include respond-below(lg) {
        grid-template-columns: calc((100% - 16px) / 2) calc((100% - 16px) / 2);
        grid-gap: 1rem;
        grid-template-rows: auto;
      }

      .grid-data {
        width: 100%;
        height: 28vw;
        position: relative;
        @include respond-below(lg) {
          display: flex;
          flex-direction: column;
          height: 100%;
        }

        img {
          margin: 0;
          width: 100%;
          height: 100%;
          object-position: top;
          object-fit: cover;
          @include respond-below(lg) {
            height: auto;
          }
        }

        .grid-desc {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 1) 7%,
            rgba(255, 255, 255, 0) 50%
          );
          padding: 0 2rem 2rem;

          @include respond-below(lg) {
            justify-content: end;
            text-align: center;
            background: none;
            align-items: center;
            position: inherit;
            padding: 0;
          }

          .title {
            color: $clr_txt_white;
            font-family: "PlayfairDisplayRegular";
            font-size: 1.2rem;
            margin-bottom: 0.1rem;
            font-weight: bold;

            @include respond-below(lg) {
              flex-grow: 2;
              color: $clr_txt_dark_black;
              font-size: 1rem;
            }
          }

          .subtitle {
            color: $clr_txt_light_green;
            font-family: "SansProSemiBold";
            font-size: 1rem;
            margin-bottom: 0.3rem;
            font-weight: bold;
          }
        }
      }
    }

    .content-download {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem;
    }
  }
}

.modal-body {
  padding: 1.5rem;
  @include respond-below(lg) {
    padding: 0px;
  }
}

.modal-header {
  border: none;
  height: 0;
  padding: 0;
  margin: 0;

  .close {
    position: absolute;
    top: 0;
    right: 0;
    background: $clr_txt_light_green;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    z-index: 99;
  }
}
