@import "../../../sass//main.scss";

.format-15 {
  padding: 2.5rem 0 2rem;

  img {
    margin: 2rem 0;
  }

  .content {
    max-width: 100%;
    .title {
      &.row {
        margin: 0;
      }

      h1 {
        width: 100%;
        color: $clr_txt_dark_green;
        font-family: "PlayfairDisplayRegular";
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 1.5rem;
      }
    }

    .desc {
        @include respond-below(lg) {
            flex-direction: column;
          }

      &.row {
        margin: 0;
      }

      .side-content {
        &:first-child {
          padding-left: 0;
          border-right: 0.5px solid $clr_line_gray;
        }

        &:last-child {
          padding-right: 0;
        }

        &.col{
            @include respond-below(lg) {
                width: 100%;
                padding: 1.5rem;
              }
        }

       
      }

      p {
        width: 100%;
        color: $clr_txt_dark_black;
        font-family: "SansProRegular";
        font-size: 1rem;
        text-align: justify;
        line-height: 1.9rem;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      img {
        width: 100%;
      }

      * {
        width: 100%;
      }
    }
  }

  .box-image {
    &.row {
      margin: 0;
    }
  }
}
