@import "../../sass/main.scss";


.box-loader-search{
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include respond-below(lg) {
    margin-top: 80px;
  } 
}

.page-search{
    width: 100%;
    height: auto;
    padding: 5rem 10rem;
    max-width: 1645px;
    margin: auto;

    @include respond-below(lg) {
      padding: 7rem 1.5rem 2rem 1.5rem;
    }

    .input-search{
      position: relative;
      width: 100%;

      .ic-luv{
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
      }

      .btn-search{
        position: absolute;
        top: 50%;
        right: 4px;
        transform: translateY(-50%);
      }

      input{
        width: 100%;
        border-radius: 5px;
        height: 45px;
        padding: 0px 100px 0px 48px;

      }
    }

    h1{
        text-transform: uppercase;
        width: 100%;
        color: $clr_txt_dark_green;
        font-family: "SansProBold";
        font-size: 2rem;
        text-align: center;
        margin-bottom: 1rem;
    }

    .box-search{

      margin-top: 1rem;

      .item-search{

        border-bottom: 1px solid black;
        padding-bottom: 1.2rem;
        padding-top: 1.2rem;

        h2{
          width: 100%;
          color: $clr_txt_dark_green;
          font-family: "SansProBold";
          font-size: 1.4rem;
          text-align: left;
        }
  
        .short-desc-search {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
          
          @include respond-below(lg) {
            display: block;
            display: -webkit-box;
            max-width: 100%;
            height: 100%;
            margin: 1rem 0 1rem 0;
            font-size: 14px;
            line-height: 1.5;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
  
          }
        }
      }

      

  
  }
    
}