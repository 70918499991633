@import "../../sass/main.scss";


.page-404{
    width: 100%;
    height: auto;
    padding: 10vh;

    h1{
        width: 100%;
        color: $clr_txt_dark_green;
        font-family: "SansProBold";
        font-size: 10rem;
        text-align: center;
        margin-bottom: 1rem;
    }

    h6{
        width: 100%;
        color: $clr_txt_dark_green;
        font-family: "PlayfairDisplayRegular";
        font-size: 1.5rem;
        text-align: center;
    }
}