@import "../../../sass//main.scss";

.format-14 {
  padding: 2.5rem 0 2rem;

  @include respond-below(lg) {
    padding: 1.5rem 1rem 1rem 1rem;
  }

  .content {
    max-width: 100%;

    .title {
      &.row {
        margin: 0;
      }

      h1 {
        width: 100%;
        color: $clr_txt_dark_green;
        font-family: "PlayfairDisplayRegular";
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 1.5rem;

        @include respond-below(lg) {
          margin-top: 0;
          font-size: 2rem;
        }
      }
    }

    .desc {
      &.row {
        margin: 0;
      }

      p {
        width: 100%;
        color: $clr_txt_dark_black;
        font-family: "SansProRegular";
        font-size: 1rem;
        text-align: center;
        line-height: 1.9rem;
        margin-bottom: 1.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      img {
        width: 100%;
      }
    }

    .content-data {
      display: grid;
      grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
      grid-gap: 2rem;
      padding: 0;
      width: 100%;
      margin-top: 2rem;

      @include respond-below(lg) {
        grid-template-columns: 100%;
      }

      .grid-data {
        width: 100%;
        width: 100%;
        height: 22vw;
        position: relative;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
        -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
        border: none;

        @include respond-below(lg) {
          height: 100%;
        }

        img {
          margin: 0;
          width: 100%;
          height: 100%;
          object-position: top;
          object-fit: cover;
        }

        .grid-desc {
          position: absolute;
          top: 0;
          background: rgb(0, 0, 0);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 1) 9%,
            rgba(255, 255, 255, 0) 100%
          );
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start;
          width: 100%;
          padding: 25px;
          cursor: pointer;

          &:hover{
            .box-icon-play{
              img{
                width: 20%;
                height: 20%;
              }
            }
          }


          .box-icon-play{
            position: absolute;
            top:0;
            left:0;
            background-color: transparent;
            width: inherit;
            height: inherit;
            display: flex;
            justify-content: center;
            align-items: center;
            pointer-events: none;

            img{
              width: 15%;
              height: 15%;
              object-fit: contain;
              transition: width .2s, height .2s;
            }
          }

          .date {
            color: $clr_txt_white;
            font-family: "SansProRegular";
            font-size: 1rem;
            margin-bottom: 0.1rem;
            font-weight: bold;
          }

          .title {
            color: $clr_txt_white;
            font-family: "PlayfairDisplayRegular";
            font-size: 1.2rem;
            margin-bottom: 0.1rem;
            font-weight: bold;

            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }

  .filter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond-below(lg) {
      display: flex;
      flex-direction: column;
    }

    .title {
      color: $clr_txt_dark_black;
      font-family: "SansProSemiBold";
      font-size: 1rem;
      font-weight: bold;
    }

    .action-select {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      @include respond-below(lg) {
        margin-top: 1rem;
      }

      .dropdown {
        margin-left: 10px;

        @include respond-below(lg) {
          margin-left: 0;
        }

        .btn-primary {
          margin-left: 10px;
          background-color: transparent;
          border: none;
          color: black;
          border-bottom: 1px solid #00000038;
          border-radius: 0;
        }
      }
    }
  }

  .action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem 2rem;
  }
}
