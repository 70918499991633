@import "../../../sass//main.scss";

.format-1 {
  padding: 2.5rem 0 2rem;
  @include respond-below(lg) {
    padding: 2.5rem 1.5rem 2rem;
  }
  img {
    margin: 2rem 0;
  }

  .content {
    .title {
      &.row {
        margin: 0;
      }

      h1 {
        width: 100%;
        color: $clr_txt_dark_green;
        font-family: "PlayfairDisplayRegular";
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 1.5rem;
        @include respond-below(lg) {
          font-size: 2rem;
        }
      }
    }

    .desc {
      &.row {
        margin: 0;
      }

      p {
        width: 100%;
        color: $clr_txt_dark_black;
        font-family: "SansProRegular";
        font-size: 1rem;
        text-align: center;
        line-height: 1.9rem;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      img {
        width: 100%;
      }

      * {
        width: 100%;
      }
    }
  }

  @include respond-below(lg) {
    .content{
      margin-top: 50px;
    }
  }
}
