//text color
$clr_txt_dark_black: #000000;
$clr_txt_black: #191919;
$clr_txt_gray: #707070;
$clr_txt_dark_green: #0A5A23;
$clr_txt_light_green: #81C42A;
$clr_txt_white: #FFFFFF;

//background color
$clr_bg_gray: #EDEDED;
$clr_bg_light_gray: #F7F7F7;
$clr_bg_light_gray2: #E4E4E4;
$clr_bg_dark_gray: #707070;
$clr_bg_white: #FFFFFF;
$clr_bg_sub_menu: #e4e4e4cc;
$clr_bg_sub_menu_sub: #ffffffcc;
$clr_bg_dark_green: #0A5A23;


//line color
$clr_line_gray: #B3B3B3;
$clr_line_dark_gray: #707070;

//border color
$clr_broder_gray: #B3B3B3;

//Headline, Highlight, Buttons and others color
$clr_dark_green: #0A5A23;
$clr_green: #1BB14A;
$clr_light_green: #81C42A;
$clr_transparent: #ffffff00;
$clr_error_sign: #E86262;
