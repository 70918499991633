@import "../../../sass//main.scss";

.format-10 {
  max-width: calc(100% / 2);
  padding: 0;
  display: inline-block;
  position: relative;
  height: 550px;

  @include respond-below(lg) {
    max-width: 100%;
  }

  img {
    position: relative;
    z-index: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .content {
    width: 100%;
    padding: 0 16%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 15%;

    @include respond-below(lg) {
      justify-content: center;
    }

    .img-icon {
      width: 15%;
      height: 15%;
      object-fit: contain;
    }

    ul {
      color: $clr_txt_white;
      font-family: "SansProLight";
      font-size: 1.2rem;
    }

    .title {
      &.row {
        margin: 0;
      }

      h1 {
        width: 100%;
        color: $clr_txt_white;
        font-family: "PlayfairDisplayRegular";
        font-size: 2.5rem;
        text-align: left;
        margin-bottom: 1.5rem;
        margin-top: 3rem;

        @include respond-below(lg) {
          font-size: 2rem;
          text-align: center;
        }
      }
    }

    .subtitle {
      &.row {
        margin: 0;
      }

      span {
        width: 100%;
        color: $clr_txt_white;
        font-family: "SansProLight";
        font-size: 1.5rem;
        font-style: italic;
        text-align: left;
        line-height: 1.9rem;
        margin-bottom: 1.5rem;

        &::before,
        &::after {
          content: '"';
        }
      }
    }

    .desc {
      &.row {
        margin: 0;
      }

      p {
        width: 100%;
        color: $clr_txt_white;
        font-family: "SansProLight";
        font-size: 1rem;
        text-align: left;
        line-height: 1.9rem;
        margin-bottom: 1rem;

        @include respond-below(lg) {
          text-align: center;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      * {
        width: 100%;
      }
    }
  }
}
