@import "~bootstrap/scss/bootstrap";
@import "./sass//main.scss";

#root{
  overflow-x: hidden;
}

.preview-page {
  width: 100vw;
  height: auto;
  position: fixed;
  z-index: 1;
  opacity: 0.5;
}

.preview-page > img {
  width: 100%;
  height: 100%;
}

a{
  color : $clr_dark_green;
  font-weight: 500;
}

/*================*/

body {
  font-family: "SansProRegular";
}

/*===============*/
/*==== UTILS ====*/
/*===============*/
.no-pad { padding: 0 !important; }
.no-left-pad { padding-left: 0 !important; }
.no-top-pad { padding-top: 0 !important; }
.no-rigth-pad { padding-right: 0 !important; }
.no-bottom-pad { padding-bottom: 0 !important; }

/*===================*/
/*==== CONTAINER ====*/
/*===================*/

.container-lg{
  max-width: 1140px;
}

.container-xl{
  //max-width: 1320px;
  max-width: 1200px;
}

.content{
  max-width: 100%;
  margin: auto;
}

@include media-breakpoint-up(lg) {
  .content{
    max-width: 960px;
  }
}


/*================*/
/*==== NAVBAR ====*/
/*================*/

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(lg) {
  .navbar {
    align-items: flex-end;
    background-color: #ffffff;
    padding: 0px 0px;
    height: 105px;
    width: 100%;
  }

  .navbar-brand {
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar-brand > img {
    width: 165px;
    object-fit: contain;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    display: block;
    font-family: "SansProSemiBold";
    font-size: 0.9rem;
    color: $clr_txt_black;
    padding: 0 0 0.5rem;
    margin: 0 0.5rem;
    border-bottom: 6px solid $clr_transparent;

    &:hover{
        color: $clr_txt_black;
        border-bottom: 6px solid $clr_light_green;
    }

    &.active{
        color: $clr_txt_black;
        border-bottom: 6px solid $clr_light_green;
    }
  }


  .navbar-collapse {
    justify-content: flex-end;
  }
}


/*================*/
/*==== BANNER ====*/
/*================*/

.banner-page {
  width: 100%;
  height: 450px;
  object-fit: cover;
  object-position: top;
  margin-bottom: 2rem;

  @include respond-below(lg) {
    margin-top: 80px;
  } 
}

.banner-slides{
  width: 100%;
  height: 750px;
  object-fit: cover;
  object-position: top;
  margin-bottom: 2rem;
}

.banner-footer{
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: top;
  position: relative;
}


.box-loader{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-loader-content{
  width: 100vw;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @include respond-below(lg) {
    margin-top: 80px;
  } 
}