@import "../../../sass//main.scss";

.format-2{
    padding: 2.5rem 0 2rem;
    
    .content{
        margin-top: 3rem;
        display: flex;
        flex-direction: row;

        @include respond-below(lg) {
            flex-direction: column;
            margin-left: 2rem;
            margin-right: 2rem;
          }
    
        .title{
            &.row{
                margin: 0;
            }

            h1{
                width: 100%;
                color: $clr_txt_dark_green;
                font-family: "PlayfairDisplayRegular";
                font-size: 2.5rem;
                text-align: center;
                margin-bottom: 1.5rem;
            }
        }

        .divider{
            &.row{
                margin: 0.4rem 2.5rem;
            }

            border-left: .1rem solid $clr_broder_gray;
        }
    
        .desc{
            &.row{
                margin: 0;
            }

            p{
                width: 100%;
                color: $clr_txt_dark_black;
                font-family: "SansProRegular";
                font-size: 1rem;
                text-align: justify;
                line-height: 1.9rem;
                margin-bottom: 1rem;

                &:last-child{
                    margin-bottom: 0;
                }
            }

            *{
                width: 100%;
            }
        }
    }
}
