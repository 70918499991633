@import "../../../sass//main.scss";

.format-6 {
  position: relative;
  margin: 2.5rem 0 0;
  max-width: 100%;
  height: 700px;
  padding: 0;

  background-image: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  @include respond-below(lg) {
    height: 300px;
  }

  &:hover {
    .img-icon {
      width: 12.5%;
      height: 12.5%;
      @include respond-below(lg) {
        height: 22.5%;
        width: 22.5%;
      }
    }
  }

  .img-icon {
    width: 10%;
    height: 10%;
    object-fit: contain;
    transition: width 0.2s, height 0.2s;
    @include respond-below(lg) {
      height: 20%;
      width: 20%;
    }
  }
}
