@import "../../../sass//main.scss";

.format-form-email {
  max-width: 100%;
  width: 100%;
  position: relative;
  height: 450px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  .img-bg {
    height: 450px;
    width: unset;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    img {
      width: 100%;
      height: unset;
      object-fit: cover;
      object-position: center;
    }
  }

  .content {
    position: absolute;
    height: 450px;
    max-width: unset;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    width: 70%;

    @include respond-below(lg) {
      width: 90%;
    }

    .title {
      &.row {
        margin: 0;
      }

      h1 {
        width: 100%;
        color: $clr_txt_white;
        font-family: "SansProRegular";
        font-size: 1rem;
        text-align: left;
        @include respond-below(lg) {
          font-size: .8rem;
        }
      }
    }

    .desc {
      &.row {
        margin: 0;

      }

      &.subtitle2{
        margin-bottom: 1rem;
      }

      p {
        width: 100%;
        color: $clr_txt_white;
        font-family: "PlayfairDisplayRegular";
        font-size: 2rem;
        text-align: left;
        line-height: 3rem;
        margin-bottom: 0;

        
        @include respond-below(lg) {
          font-size: 1.5rem;
          line-height: 2rem;
        }
      }
    }

    .form-email{
      width: 60%;

      @include respond-below(lg) {
        width: 100%;
      }

      .form-group-tap{
        margin-bottom: .7rem;

        textarea{
          resize: none;
          margin-bottom: 1.2rem;

          @include respond-below(lg) {
            margin-bottom: unset;
          }

        }
      }
    }
  }

  // .content {
  //   .title {
  //     &.row {
  //       margin: 0;
  //     }

  //     h1 {
  //       width: 100%;
  //       color: $clr_txt_dark_green;
  //       font-family: "PlayfairDisplayRegular";
  //       font-size: 2.5rem;
  //       text-align: center;
  //       margin-bottom: 1.5rem;
  //       @include respond-below(lg) {
  //         font-size: 2rem;
  //       }
  //     }
  //   }

  //   .desc {
  //     &.row {
  //       margin: 0;
  //     }

  //     p {
  //       width: 100%;
  //       color: $clr_txt_dark_black;
  //       font-family: "SansProRegular";
  //       font-size: 1rem;
  //       text-align: center;
  //       line-height: 1.9rem;
  //       margin-bottom: 1rem;

  //       &:last-child {
  //         margin-bottom: 0;
  //       }
  //     }

  //     img {
  //       width: 100%;
  //     }

  //     * {
  //       width: 100%;
  //     }
  //   }
  // }
}
