@import "../../../sass//main.scss";

.format-13 {
  padding: 2.5rem 0 2rem;

  .content {
    max-width: 100%;

    .title {
      &.row {
        margin: 0;
      }

      h1 {
        width: 100%;
        color: $clr_txt_dark_green;
        font-family: "PlayfairDisplayRegular";
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 1.5rem;
      }
    }

    .desc {
      &.row {
        margin: 0;
      }

      p {
        width: 100%;
        color: $clr_txt_dark_black;
        font-family: "SansProRegular";
        font-size: 1rem;
        text-align: center;
        line-height: 1.9rem;
        margin-bottom: 1.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      img {
        width: 100%;
      }
    }

    .tab-menu {
      a {
        color: $clr_txt_dark_black;
        font-family: "PlayfairDisplayRegular";
        font-size: 1.4rem;
        padding: 0 0 0.5rem;
        margin: 0 2rem;
        cursor: pointer;
        border: none;
        border-bottom: 4px solid transparent;

        &:hover {
          border-bottom: 4px solid #81c42a;
        }

        &.nav-item {
          &.nav-link {
            &.active {
              border: none;
              border-bottom: 4px solid #81c42a;
            }
          }
        }
      }
    }

    .content-data {
      display: grid;
      grid-template-columns: auto;
      grid-gap: .5rem;
      padding: 0;
      width: 100%;
      margin-top: .5rem;

      .grid-data {
        width: 100%;
        height: max-content;
        position: relative;
        border-bottom: 1px solid #00000038;
        padding: 10px 0;
          
        .grid-desc {
          cursor: pointer;
          height: 15%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          width: 100%;

          .dot{
            background-color: $clr_txt_light_green;
            border-radius: 100%;
            width: 10px;
            height: 10px;
            margin-right: 20px;
          }

          .date{
            color: $clr_txt_dark_black;
            font-family: "SansProRegular";
            font-size: 1.2rem;
          }

          .divider{
            margin: 0 5px;
          }

          .name {
            color: $clr_txt_dark_green;
            font-family: "SansProRegular";
            font-size: 1.2rem;
          }

          
        }
      }
    }
  }

  .filter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      color: $clr_txt_dark_black;
      font-family: "SansProSemiBold";
      font-size: 1rem;
      font-weight: bold;
    }

    .action-select {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      .dropdown {
        margin-left: 10px;

        .btn-primary {
          margin-left: 10px;
          background-color: transparent;
          border: none;
          color: black;
          border-bottom: 1px solid #00000038;
          border-radius: 0;
        }
      }
    }
  }

  .action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem 2rem;
  }
}
