@import "../../sass/main.scss";

.page-container {
  &.header-mobile{
    position: fixed;
    z-index: 9999999;
    background: white;
    width: 100%;
    .box-nav{
      width: 90%;
      margin: 0 auto;
      height: 80px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: .5rem 0;


      .box-end{
        display: flex;
        align-items: flex-end;
        height: 100%;
        .burger{
          cursor: pointer;
        }
  
        .search-icon{
          cursor: pointer;
          background: $clr_light_green;
          border-radius: 10px;
          padding: .3rem;
        }
  
      }

     
      .logo-mobile{
        width: 65%;
        height: 100%;

        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .box-menu{
      position: absolute;
      top:80px;
      left: 0;
      z-index: 99999;
      width: 100%;
      background: #0000008c;
      pointer-events: none;
      display: none;

      &.active{
        display: block;
      }
     
      .list-menu{
        pointer-events: all;
        position: absolute;
        top:0;
        left:-100%;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        background: $clr_bg_white;
        transition: all .5s;

        &.active{
          left: 0;
        }
        
        .card{
          .card-header{
            background: $clr_bg_white;
            padding-top: 0;
            padding-bottom: 0;

            &.lang-menu{
              // justify-content: flex-end;
              // display: flex;
            }

            button{
              padding-bottom: 12px;
              padding-top: 12px;
              height: 100%;
              background: transparent;
              border : none;

              &.text-menu{
                font-family: "SansProRegular";
                color: $clr_txt_dark_black;
                cursor: pointer;

                &.active{
                  border-bottom: 3px solid $clr_light_green;
                }

                &.lang-active{
                  color: $clr_light_green;
                }

                &.level2{
                  margin-left:1rem;
                }

                &.level3{
                  margin-left:2rem;
                }
              }
            } 
          }
        }
        
      }
    }
  }

  &.header-desktop {
    display: none;
    z-index: 99999999;
    position: relative;
    margin: auto;
    justify-content: space-between;
    align-items: center;

    img {
        cursor: pointer;
    }

    .nav {
      height: 100%;
      width: 100%;
      justify-content: flex-end;
      align-items: flex-end;

      .menu {
        display: flex;
        flex-direction: row;



        .text {
          position: relative;
          height: 100%;
          font-family: "SansProRegular";
          color: $clr_txt_dark_black;
          cursor: pointer;

          
        }
      }

      .action {
        display: flex;
        flex-direction: row;

        .text {
          height: 100%;
          font-family: "SansProSemiBold";
          color: $clr_txt_dark_black;
          cursor: pointer;

          &.active{
            color: $clr_light_green;
            font-weight: 500;
          }

          img{
            width: 75%;
            height: 75%;
            object-fit: contain;
          }
        }
      }
    }
  }
}

@include respond-above(lg) {
    .page-container {
      &.header-mobile{
        display: none;
      }

      &.header-desktop {
        display: flex;
        height: 95px;
        max-width: 90%;
       
        img {
          &.logo {
            width: 25%;
          }
        }
  
        .nav {
          .menu {
            height: 28px;
  
            .text {
              margin-right: 25px;
              font-size: 12px;

              &.active{
                border-bottom: 4px solid $clr_light_green;
              }
          
              &:hover {
                border-bottom: 4px solid $clr_light_green;
                
                .sub-menu{
                    display: flex;
                }
              }
    
              .sub-menu{
                  display: none;
                  height: auto;
                  width: max-content;
                  background-color: $clr_bg_sub_menu;
                  position: absolute;
                  top: calc(100% + 4px);
                  left:0;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  padding: 15px 0px;

                  .text-submenu{
                    width: 100%;
                      font-family: "SansProRegular";
                      padding-left: 30px;
                      padding-right: 30px;
                      padding: 10px 30px;
                      border-bottom: 1px solid $clr_transparent;

                      &:last-child{
                          margin-bottom: 0;
                      }

                      &:hover{
                          font-weight: bold;
                            //border-bottom: 1px solid $clr_txt_dark_black;
                            text-decoration: underline;
                            .sub{
                                display: flex;
                            }

                            .sub-menu{
                                display: flex;
                            }
                      }

                      .sub{
                        display: none;
                        height: auto;
                        min-height: 100%;
                        width: max-content;
                        background-color: $clr_bg_sub_menu_sub;
                        position: absolute;
                        top: 0;
                        left: 100%;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        padding: 15px 0px;

                        &.right{
                          left:unset;
                          right: 100%;
                        }
                      }
                  }
              }
  
              &:last-child {
                margin-right: 0px;
              }
            }
          }
  
          .action {
            height: 28px;
  
            .text {
              margin-right: 25px;
              font-size: 12px;
           
              &:hover {
                border-bottom: 4px solid $clr_light_green;
              }
  
              &:first-child {
                margin-left: 25px;
              }
  
              &:last-child {
                margin-right: 0px;
              }
            }
          }
        }
      }
    }
  }

@include respond-above(xl) {
    .page-container {
      &.header-mobile{
        display: none;
      }

      &.header-desktop {
        display: flex;
        height: 95px;
        max-width: 85%;
       
        img {
          &.logo {
            width: 25%;
          }
        }
  
        .nav {
          .menu {
            height: 28px;
  
            .text {
              margin-right: 25px;
              font-size: 14px;
          
              &:hover {
                border-bottom: 4px solid $clr_light_green;
                
                .sub-menu{
                    display: flex;
                }
              }
    
              .sub-menu{
                  display: none;
                  height: auto;
                  width: max-content;
                  background-color: $clr_bg_sub_menu;
                  position: absolute;
                  top: calc(100% + 4px);
                  left:0;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
              }
  
              &:last-child {
                margin-right: 0px;
              }
            }
          }
  
          .action {
            height: 28px;
  
            .text {
              margin-right: 25px;
              font-size: 14px;
           
              &:hover {
                border-bottom: 4px solid $clr_light_green;
              }
  
              &:first-child {
                margin-left: 25px;
              }
  
              &:last-child {
                margin-right: 0px;
              }
            }
          }
        }
      }
    }
  }

@include respond-above(xxl) {
    .page-container {
      &.header-mobile{
        display: none;
      }

      &.header-desktop {
        display: flex;
        height: 95px;
        max-width: 85%;
       
        img {
          &.logo {
            width: 25%;
          }
        }
  
        .nav {
          .menu {
            height: 28px;
  
            .text {
              margin-right: 25px;
              font-size: 1rem;
          
              &:hover {
                border-bottom: 4px solid $clr_light_green;
                
                .sub-menu{
                    display: flex;
                }
              }
    
              .sub-menu{
                  display: none;
                  height: auto;
                  width: max-content;
                  background-color: $clr_bg_sub_menu;
                  position: absolute;
                  top: calc(100% + 4px);
                  left:0;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
              }
  
              &:last-child {
                margin-right: 0px;
              }
            }
          }
  
          .action {
            height: 28px;
  
            .text {
              margin-right: 25px;
              font-size: 1rem;
           
              &:hover {
                border-bottom: 4px solid $clr_light_green;
              }
  
              &:first-child {
                margin-left: 25px;
              }
  
              &:last-child {
                margin-right: 0px;
              }
            }
          }
        }
      }
    }
  }

@include respond-above(xxxl) {
  .page-container {
    &.header-mobile{
      display: none;
    }

    &.header-desktop {
      display: flex;
      height: 130px;
      max-width: 1645px;
     
      img {
        &.logo {
          width: 25%;
        }
      }

      .nav {
        .menu {
          height: 44px;

          .text {
            margin-right: 35px;
            font-size: 18px;
        
            &:hover {
                border-bottom: 4px solid $clr_light_green;
                
                .sub-menu{
                    display: flex;
                }
              }
    
              .sub-menu{
                  display: none;
                  height: auto;
                  width: max-content;
                  background-color: $clr_bg_sub_menu;
                  position: absolute;
                  top: calc(100% + 4px);
                  left:0;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
              }

            &:last-child {
              margin-right: 0px;
            }
          }
        }

        .action {
          height: 44px;

          .text {
            margin-right: 35px;
            font-size: 18px;
         
            &:hover {
              border-bottom: 5px solid $clr_light_green;
            }

            &:first-child {
              margin-left: 35px;
            }

            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}
