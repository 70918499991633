@import "../../../sass//main.scss";

.format-5 {
  position: relative;
  padding: 1rem 0 0;
  @include respond-below(lg) {
    padding: 1rem 3rem 0;
  }
  .box-img-rect {
    height: 300px;
    @include respond-below(lg) {
      height: 150px;
    }
    .img-rectangle {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      filter: brightness(0.6);

      // &:hover{
      //   filter: brightness(1);
      // }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      height: 100%;
      width: 100%;

      &:hover {
        .action {
          display: block;
        }

        // .img-rectangle{
        //   filter: brightness(1);
        // }
      }

      .title {
        h1 {
          width: 100%;
          color: $clr_txt_dark_green;
          font-family: "PlayfairDisplayRegular";
          font-size: 2.5rem;
          text-align: center;
          color: $clr_txt_white;

          @include respond-below(lg) {
            font-size: 2rem;
            max-width: 12rem;
          }
        }
      }

      .action {
        display: none;
      }
    }
  }
}
