@import "../../../sass//main.scss";

.format-17 {
  padding: 2.5rem 0 2rem;

  .content {
    .filter {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 1rem;
  
      @include respond-below(lg) {
        justify-content: center;
      }
  
      .title {
        color: $clr_txt_dark_black;
        font-family: "SansProSemiBold";
        font-size: 1rem;
        font-weight: bold;
      }
  
      .action-select {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
  
        .dropdown {
          margin-left: 10px;
  
          .btn-primary {
            margin-left: 10px;
            background-color: transparent;
            border: none;
            color: black;
            border-bottom: 1px solid #00000038;
            border-radius: 0;
          }
        }
      }
    }
  
    .action {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4rem 2rem 2rem;
    }

    max-width: 100%;

    .title {
      &.row {
        margin: 0;
      }

      h1 {
        width: 100%;
        color: $clr_txt_dark_green;
        font-family: "PlayfairDisplayRegular";
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 1.5rem;
      }
    }

    .desc {
      &.row {
        margin: 0;
        margin-bottom: 3.5rem;
      }

      p {
        width: 100%;
        color: $clr_txt_dark_black;
        font-family: "SansProRegular";
        font-size: 1rem;
        text-align: center;
        line-height: 1.9rem;
        margin-bottom: 1.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      img {
        width: 100%;
      }
    }

    .tab-menu {
      a {
        color: $clr_txt_dark_black;
        font-family: "PlayfairDisplayRegular";
        font-size: 1.4rem;
        padding: 0 0 0.5rem;
        margin: 0 2rem;
        cursor: pointer;
        border: none;
        border-bottom: 4px solid transparent;

        &:hover {
          border-bottom: 4px solid #81c42a;
        }
        @include respond-below(lg) {
          font-size: 0.5rem;
          padding: 1rem;
          margin: 0;
        }

        &.nav-item {
          &.nav-link {
            &.active {
              border: none;
              border-bottom: 4px solid #81c42a;
            }
          }
        }
      }
    }

    .content-data {
      display: grid;
      grid-template-columns: auto;
      grid-gap: 0.5rem;
      padding: 0;
      width: 100%;
      margin-top: 0.5rem;

      .grid-data {
        width: 100%;
        height: max-content;
        position: relative;
        border-bottom: 1px solid #00000038;
        padding: 10px 10px;

        @include respond-below(lg) {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .grid-desc {
          cursor: pointer;
          height: 15%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          width: 100%;

          @include respond-below(lg) {
            padding-left: 0;
          }

          .dot {
            background-color: $clr_txt_light_green;
            border-radius: 100%;
            width: 10px;
            height: 10px;
            margin-right: 20px;
            @include respond-below(lg) {
              margin-right: 8px;
            }
          }

          .date {
            color: $clr_txt_dark_black;
            font-family: "SansProRegular";
            font-size: 1.2rem;
          }

          .divider {
            margin: 0 5px;
          }

          .name {
            color: $clr_txt_dark_green;
            font-family: "SansProRegular";
            font-size: 1.2rem;
            @include respond-below(lg) {
              font-size: 0.8rem;
            }
          }
        }
      }
    }

    .content-download {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem;
    }
  }
}

.modal-body {
  padding: 1.5rem;
}

.modal-header {
  border: none;
  height: 0;
  padding: 0;
  margin: 0;

  .close {
    position: absolute;
    top: 0;
    right: 0;
    background: $clr_txt_light_green;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    z-index: 99;
  }
}
