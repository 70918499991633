@import "../../../sass/main.scss";

.modal-1 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 0;

  @include respond-below(lg) {
    flex-direction: column;
  }

  .box-img-photo {
    margin: 0;
    width: 35%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @include respond-below(lg) {
      width: 100%;
    }

    img {
      &.photo {
        height: 200px;
        width: 100%;
        object-fit: cover;
        object-position: 100% 5%;
        //margin: 2rem 0;
      }
    }

    @include respond-below(lg) {
      display: block;
      align-self: center;
    }
  }

  .content {
    width: 65%;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;

    flex-direction: column;
    margin: 0;
    padding-left: 1.5rem;

    @include respond-below(lg) {
      width: 100%;
      padding: 0;
      margin-top: 1rem;
    }

    .title {
      &.row {
        margin: 0;
      }

      h1 {
        width: 100%;
        color: $clr_txt_dark_green;
        font-family: "PlayfairDisplayRegular";
        font-size: 1.5rem;
        text-align: left;
        margin-bottom: 0.5rem;
      }
    }

    .subtitle {
      &.row {
        margin: 0;
      }

      h5 {
        width: 100%;
        color: $clr_txt_dark_black;
        font-family: "PlayfairDisplayRegular";
        font-style: italic;
        font-size: 1rem;
        text-align: left;
      }
    }

    .desc {
      &.row {
        margin: 0;
        max-height: 45vh;
        overflow: auto;
      }

      p {
        width: 100%;
        color: $clr_txt_dark_black;
        font-family: "SansProRegular";
        font-size: 1rem;
        text-align: justify;
        line-height: 1.9rem;
        margin-bottom: 1.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      img {
        width: 100%;
      }
    }

    .divider {
      &.row {
        width: 100%;
        margin: 0;

        .col {
          padding: 0;

          hr {
            border-color: $clr_line_dark_gray;
          }
        }
      }
    }
  }
}
