@import "../../sass/main.scss";

.detail-page {
  padding: 2.5rem 0 2rem;

  @include respond-below(lg) {
    padding: 5rem 1.5rem 2rem 1.5rem;
  }

  .detail-page--content {
    border-right: 1px solid $clr_line_gray;
    padding-right: 1.5rem;

    @include respond-below(lg) {
      border-right: none;
      padding-right: 1rem;
    }

    .box-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .title {
        width: 100%;
        color: $clr_txt_dark_green;
        font-family: "PlayfairDisplayRegular";
        font-size: 2.5rem;
        text-align: left;
        margin-bottom: 0.1rem;
      }
      .date {
        color: $clr_txt_dark_black;
        font-family: "SansProRegular";
        font-size: 1rem;
        margin-bottom: 0.1rem;
      }
    }

    img.img-news {
      width: 100%;
      // height: 50vh;
      object-fit: cover;
      object-position: top;
      margin: 1rem 0;
    }

    .box-desc {
      padding-bottom: 1.5rem;
      border-bottom: 1px solid $clr_line_gray;
      &.row {
        margin: 0;
      }

      p {
        width: 100%;
        color: $clr_txt_dark_black;
        font-family: "SansProRegular";
        font-size: 1rem;
        text-align: center;
        line-height: 1.9rem;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      img {
        width: 100%;
      }

      * {
        width: 100%;
      }
    }

    .box-nav {
      margin-top: 1.5rem;
      display: flex;
      justify-content: space-between;

      @include respond-below(lg) {
        justify-content: space-evenly;
      }

      .prev {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 40%;

        .nav {
          cursor: pointer;
          color: $clr_txt_dark_green;
          font-family: "SansProRegular";
          font-size: 1rem;
          text-align: left;
          line-height: 1.9rem;
        }

        .name {
          color: $clr_txt_dark_black;
          font-family: "SansProRegular";
          font-size: 1.5rem;
          text-align: left;
          cursor: pointer;
        }
      }

      .divider {
        border-left: 1px solid $clr_line_gray;
      }

      .next {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        width: 40%;
        .nav {
          cursor: pointer;
          color: $clr_txt_dark_green;
          font-family: "SansProRegular";
          font-size: 1rem;
          text-align: right;
          line-height: 1.9rem;
        }

        .name {
          color: $clr_txt_dark_black;
          font-family: "SansProRegular";
          font-size: 1.5rem;
          text-align: right;
          cursor: pointer;
        }
      }
    }
  }

  .detail-page--side {
    padding-left: 1.5rem;
    .recent-news {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 1.5rem;
      .title-side-news {
        width: 100%;
        color: $clr_txt_dark_green;
        font-family: "PlayfairDisplayRegular";
        font-size: 1.5rem;
        text-align: left;
        margin-bottom: 0.8rem;
      }

      .list-item {
        color: $clr_txt_dark_black;
        font-family: "SansProRegular";
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
        cursor: pointer;
      }
    }

    .related-news {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 1.5rem;
      .title-side-news {
        width: 100%;
        color: $clr_txt_dark_green;
        font-family: "PlayfairDisplayRegular";
        font-size: 1.5rem;
        text-align: left;
        margin-bottom: 0.8rem;
      }

      .list-item {
        color: $clr_txt_dark_black;
        font-family: "SansProRegular";
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
        cursor: pointer;
      }
    }
  }
}
